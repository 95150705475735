
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';

import { SettingsGeneralService } from '../settings-general.service';
import { SubscriptionsSettingsModel } from '../models/subscriptions-settings.model';

@Component({
    components: {
        CustomSelect,
        CustomMultiSelect,
        PageWrapper,
    },
})
export default class EmailReportsGeneral extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    isChanged = false;
    isError = false;
    isSaving = false;
    statusText = '';

    yesNoItems = [
        { name: this.$tc('yes'), value: true },
        { name: this.$tc('no'), value: false },
    ];

    updateChangeStatus() {
        this.isChanged = true;
    }

    rangeItems = [5, 7, 14].map(value => ({
        name: this.$t('numDays', [value]).toString(),
        value,
    }));

    subscriptions: SubscriptionsSettingsModel | null = null;

    beforeMount() {
        this.subscriptions = { ...this.settingsGeneralService.subscriptions };
    }

    async saveSettings() {
        this.statusText = '';
        this.isError = false;
        this.isSaving = true;

        try {
            await this.settingsGeneralService.saveSubscriptionsSettings(this.subscriptions!);

            this.statusText = this.$tc('saved');
            this.isChanged = false;
        } catch {
            this.statusText = this.$tc('somethingWrong');
            this.isError = true;
        } finally {
            this.isSaving = false;
        }
    }
}
